
import Vue from "vue";
import TiposFechamentoList from "@/components/subviews/tipos-fechamento/tipos-fechamento-list.vue";
import TiposFechamentoMaintenance from "@/components/subviews/tipos-fechamento/tipos-fechamento-maintenance.vue";
import ITipoFechamento from "@/lib/interfaces/tipo-fechamento";

export default Vue.extend({
  name: "TiposFechamento",

  components: { TiposFechamentoList, TiposFechamentoMaintenance },

  data() {
    return {
      ret_id: 1,
      ret_msg: "",

      refresh: 0,

      currentTab: 0,

      tipoFechamento: {} as ITipoFechamento,
    };
  },
});
